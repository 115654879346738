module.exports = [{
      plugin: require('/tmp/3603a456/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/3603a456/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null,null]},
    },{
      plugin: require('/tmp/3603a456/docs/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
