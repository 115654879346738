// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-md": () => import("/tmp/3603a456/docs/src/pages/404.md" /* webpackChunkName: "component---src-pages-404-md" */),
  "component---src-pages-about-md": () => import("/tmp/3603a456/docs/src/pages/about.md" /* webpackChunkName: "component---src-pages-about-md" */),
  "component---src-pages-advanced-api-md": () => import("/tmp/3603a456/docs/src/pages/advanced/api.md" /* webpackChunkName: "component---src-pages-advanced-api-md" */),
  "component---src-pages-advanced-ast-md": () => import("/tmp/3603a456/docs/src/pages/advanced/ast.md" /* webpackChunkName: "component---src-pages-advanced-ast-md" */),
  "component---src-pages-advanced-components-md": () => import("/tmp/3603a456/docs/src/pages/advanced/components.md" /* webpackChunkName: "component---src-pages-advanced-components-md" */),
  "component---src-pages-advanced-contributing-md": () => import("/tmp/3603a456/docs/src/pages/advanced/contributing.md" /* webpackChunkName: "component---src-pages-advanced-contributing-md" */),
  "component---src-pages-advanced-custom-loader-md": () => import("/tmp/3603a456/docs/src/pages/advanced/custom-loader.md" /* webpackChunkName: "component---src-pages-advanced-custom-loader-md" */),
  "component---src-pages-advanced-index-md": () => import("/tmp/3603a456/docs/src/pages/advanced/index.md" /* webpackChunkName: "component---src-pages-advanced-index-md" */),
  "component---src-pages-advanced-plugins-md": () => import("/tmp/3603a456/docs/src/pages/advanced/plugins.md" /* webpackChunkName: "component---src-pages-advanced-plugins-md" */),
  "component---src-pages-advanced-retext-plugins-md": () => import("/tmp/3603a456/docs/src/pages/advanced/retext-plugins.md" /* webpackChunkName: "component---src-pages-advanced-retext-plugins-md" */),
  "component---src-pages-advanced-runtime-md": () => import("/tmp/3603a456/docs/src/pages/advanced/runtime.md" /* webpackChunkName: "component---src-pages-advanced-runtime-md" */),
  "component---src-pages-advanced-specification-md": () => import("/tmp/3603a456/docs/src/pages/advanced/specification.md" /* webpackChunkName: "component---src-pages-advanced-specification-md" */),
  "component---src-pages-advanced-sync-api-md": () => import("/tmp/3603a456/docs/src/pages/advanced/sync-api.md" /* webpackChunkName: "component---src-pages-advanced-sync-api-md" */),
  "component---src-pages-advanced-transform-content-md": () => import("/tmp/3603a456/docs/src/pages/advanced/transform-content.md" /* webpackChunkName: "component---src-pages-advanced-transform-content-md" */),
  "component---src-pages-advanced-typescript-md": () => import("/tmp/3603a456/docs/src/pages/advanced/typescript.md" /* webpackChunkName: "component---src-pages-advanced-typescript-md" */),
  "component---src-pages-advanced-writing-a-plugin-md": () => import("/tmp/3603a456/docs/src/pages/advanced/writing-a-plugin.md" /* webpackChunkName: "component---src-pages-advanced-writing-a-plugin-md" */),
  "component---src-pages-blog-custom-pragma-md": () => import("/tmp/3603a456/docs/src/pages/blog/custom-pragma.md" /* webpackChunkName: "component---src-pages-blog-custom-pragma-md" */),
  "component---src-pages-blog-index-md": () => import("/tmp/3603a456/docs/src/pages/blog/index.md" /* webpackChunkName: "component---src-pages-blog-index-md" */),
  "component---src-pages-contributing-md": () => import("/tmp/3603a456/docs/src/pages/contributing.md" /* webpackChunkName: "component---src-pages-contributing-md" */),
  "component---src-pages-editor-plugins-md": () => import("/tmp/3603a456/docs/src/pages/editor-plugins.md" /* webpackChunkName: "component---src-pages-editor-plugins-md" */),
  "component---src-pages-editors-md": () => import("/tmp/3603a456/docs/src/pages/editors.md" /* webpackChunkName: "component---src-pages-editors-md" */),
  "component---src-pages-getting-started-babel-config-md": () => import("/tmp/3603a456/docs/src/pages/getting-started/babel-config.md" /* webpackChunkName: "component---src-pages-getting-started-babel-config-md" */),
  "component---src-pages-getting-started-create-react-app-md": () => import("/tmp/3603a456/docs/src/pages/getting-started/create-react-app.md" /* webpackChunkName: "component---src-pages-getting-started-create-react-app-md" */),
  "component---src-pages-getting-started-gatsby-md": () => import("/tmp/3603a456/docs/src/pages/getting-started/gatsby.md" /* webpackChunkName: "component---src-pages-getting-started-gatsby-md" */),
  "component---src-pages-getting-started-index-md": () => import("/tmp/3603a456/docs/src/pages/getting-started/index.md" /* webpackChunkName: "component---src-pages-getting-started-index-md" */),
  "component---src-pages-getting-started-next-md": () => import("/tmp/3603a456/docs/src/pages/getting-started/next.md" /* webpackChunkName: "component---src-pages-getting-started-next-md" */),
  "component---src-pages-getting-started-parcel-md": () => import("/tmp/3603a456/docs/src/pages/getting-started/parcel.md" /* webpackChunkName: "component---src-pages-getting-started-parcel-md" */),
  "component---src-pages-getting-started-react-static-md": () => import("/tmp/3603a456/docs/src/pages/getting-started/react-static.md" /* webpackChunkName: "component---src-pages-getting-started-react-static-md" */),
  "component---src-pages-getting-started-typescript-md": () => import("/tmp/3603a456/docs/src/pages/getting-started/typescript.md" /* webpackChunkName: "component---src-pages-getting-started-typescript-md" */),
  "component---src-pages-getting-started-webpack-md": () => import("/tmp/3603a456/docs/src/pages/getting-started/webpack.md" /* webpackChunkName: "component---src-pages-getting-started-webpack-md" */),
  "component---src-pages-getting-started-x-0-md": () => import("/tmp/3603a456/docs/src/pages/getting-started/x0.md" /* webpackChunkName: "component---src-pages-getting-started-x-0-md" */),
  "component---src-pages-guides-custom-loader-md": () => import("/tmp/3603a456/docs/src/pages/guides/custom-loader.md" /* webpackChunkName: "component---src-pages-guides-custom-loader-md" */),
  "component---src-pages-guides-index-md": () => import("/tmp/3603a456/docs/src/pages/guides/index.md" /* webpackChunkName: "component---src-pages-guides-index-md" */),
  "component---src-pages-guides-live-code-md": () => import("/tmp/3603a456/docs/src/pages/guides/live-code.md" /* webpackChunkName: "component---src-pages-guides-live-code-md" */),
  "component---src-pages-guides-syntax-highlighting-md": () => import("/tmp/3603a456/docs/src/pages/guides/syntax-highlighting.md" /* webpackChunkName: "component---src-pages-guides-syntax-highlighting-md" */),
  "component---src-pages-guides-table-of-contents-md": () => import("/tmp/3603a456/docs/src/pages/guides/table-of-contents.md" /* webpackChunkName: "component---src-pages-guides-table-of-contents-md" */),
  "component---src-pages-guides-wrapper-customization-md": () => import("/tmp/3603a456/docs/src/pages/guides/wrapper-customization.md" /* webpackChunkName: "component---src-pages-guides-wrapper-customization-md" */),
  "component---src-pages-guides-writing-a-plugin-md": () => import("/tmp/3603a456/docs/src/pages/guides/writing-a-plugin.md" /* webpackChunkName: "component---src-pages-guides-writing-a-plugin-md" */),
  "component---src-pages-index-md": () => import("/tmp/3603a456/docs/src/pages/index.md" /* webpackChunkName: "component---src-pages-index-md" */),
  "component---src-pages-migrating-v-1-md": () => import("/tmp/3603a456/docs/src/pages/migrating/v1.md" /* webpackChunkName: "component---src-pages-migrating-v-1-md" */),
  "component---src-pages-plugins-md": () => import("/tmp/3603a456/docs/src/pages/plugins.md" /* webpackChunkName: "component---src-pages-plugins-md" */),
  "component---src-pages-projects-md": () => import("/tmp/3603a456/docs/src/pages/projects.md" /* webpackChunkName: "component---src-pages-projects-md" */),
  "component---src-pages-syntax-md": () => import("/tmp/3603a456/docs/src/pages/syntax.md" /* webpackChunkName: "component---src-pages-syntax-md" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/tmp/3603a456/docs/.cache/data.json")

