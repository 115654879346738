import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/getting-started"
        }}>{`Getting Started`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/getting-started/next"
            }}>{`Next.js`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/getting-started/gatsby"
            }}>{`Gatsby`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/getting-started/create-react-app"
            }}>{`Create React App`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/getting-started/react-static"
            }}>{`React Static`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/getting-started/webpack"
            }}>{`Webpack`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/getting-started/parcel"
            }}>{`Parcel`}</a></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/migrating/v1"
        }}>{`Migrating from v0 to v1`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides"
        }}>{`Guides`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/guides/syntax-highlighting"
            }}>{`Syntax highlighting`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/guides/live-code"
            }}>{`Live code editor`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/guides/table-of-contents"
            }}>{`Table of contents`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/guides/writing-a-plugin"
            }}>{`Writing a plugin`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/guides/custom-loader"
            }}>{`Custom loader`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/guides/wrapper-customization"
            }}>{`Wrapper customization`}</a></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/advanced"
        }}>{`Advanced`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/advanced/api"
            }}>{`API`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/advanced/runtime"
            }}>{`Runtime`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/advanced/ast"
            }}>{`AST`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/advanced/components"
            }}>{`Components`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/advanced/plugins"
            }}>{`Plugins`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/advanced/transform-content"
            }}>{`Transform Content`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/advanced/typescript"
            }}>{`Typescript`}</a></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/contributing"
        }}>{`Contributing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/projects"
        }}>{`Projects`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/editors"
        }}>{`Editors`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog"
        }}>{`Blog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/about"
        }}>{`About`}</a></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      